import 'intersection-observer';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en.js';
import '@formatjs/intl-pluralrules/locale-data/es.js';
import '@formatjs/intl-pluralrules/locale-data/fr.js';
import '@formatjs/intl-pluralrules/locale-data/nl.js';
import '@formatjs/intl-relativetimeformat/locale-data/en.js';
import '@formatjs/intl-relativetimeformat/locale-data/es.js';
import '@formatjs/intl-relativetimeformat/locale-data/fr.js';
import '@formatjs/intl-relativetimeformat/locale-data/nl.js';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'reactjs-popup/dist/index.css';
import './css/styles.css';
import 'moment';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import 'url-search-params-polyfill';
import { Provider } from 'react-redux';
import { PostHogProvider } from './utils/posthog-provider';
import PostHogPageView from './utils/pageview.js';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route,
    RouterProvider,
    Navigate,
} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import RouteLinks from './constants/routes.js';
import { getStore } from './store';
import { onWindowResize, setDeviceType, setOnlineStatus } from './actions/siteActions';

import Authorisation from './containers/authorisation';
import Unauthorised from './pages/Error/unauthorised';
import Errorpage from './components/errorpage';
import LoginRegisterContainer from './containers/login-register-container';
import Layout from './components/Layout';
import IntlWrapper from './utils/localisation/IntlWrapper';
import customMuiTheme from './themes/mui-theme';
import { GlobalStyles } from './themes/global';
import { SpinnerWithWrapper } from './components/common/spinner';
import { lazyWithRetry } from './utils/lazyWithRetry';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const Page = (props) => {
    useEffect(() => {
        document.title = props.title || 'RefNow';
    }, [props.title]);

    return (
        <>
            <PostHogPageView />
            {props.children}
        </>
    );
};

export default Page;

const intlOpts = Intl.DateTimeFormat().resolvedOptions();

moment.locale(intlOpts.locale);

//IMPORTANT: Set the default timezone to London as this is where our servers are located
// The default timezone needs to be set and then timezones can be applied when using moment.tz()
moment.tz.setDefault('Europe/London');

//Login/Register
const Login = lazyWithRetry(() => import(/* webpackChunkName: "login" */ './pages/Login'));
const Registration = lazyWithRetry(() =>
    import(/* webpackChunkName: "register" */ './pages/Register')
);

//References
const Requests = lazyWithRetry(() => import(/* webpackChunkName: "requests" */ './pages/Requests'));

//Admin Pages
const AdminContainer = lazyWithRetry(() =>
    import(/* webpackChunkName: "adminContainer" */ './containers/admin-container')
);
const TalentPool = lazyWithRetry(() =>
    import(/* webpackChunkName: "talentPool" */ './pages/TalentPool')
);
const VacancyPool = lazyWithRetry(() =>
    import(/* webpackChunkName: "vacancyPool" */ './pages/VacancyPool')
);
const QuestionProfileHomepage = lazyWithRetry(() =>
    import(/* webpackChunkName: "questionProfiles" */ './pages/QuestionProfiles')
);
//Settings
const Settings = lazyWithRetry(() => import(/* webpackChunkName: "settings" */ './pages/Settings'));
const AccountSettings = lazyWithRetry(() =>
    import(/* webpackChunkName: "accountSettings" */ './pages/Settings/account')
);
const OrganisationSettings = lazyWithRetry(() =>
    import(/* webpackChunkName: "orgSettings" */ './pages/Settings/organisation')
);
const NotificationSettings = lazyWithRetry(() =>
    import(/* webpackChunkName: "notificationsSettings" */ './pages/Settings/notifications')
);
const SubscriptionSettings = lazyWithRetry(() =>
    import(/* webpackChunkName: "subscriptionSettings" */ './pages/Settings/Subscription')
);
const BuyCredits = lazyWithRetry(() =>
    import(/* webpackChunkName: "buyCredits" */ './pages/Settings/buy-credits')
);
const UsersAndTeams = lazyWithRetry(() =>
    import(/* webpackChunkName: "usersAndTeams" */ './pages/Settings/UsersAndTeams')
);
const Tours = lazyWithRetry(() => import(/* webpackChunkName: "tours" */ './pages/Settings/Tours'));
const ReferencingDashboard = lazyWithRetry(() =>
    import(/* webpackChunkName: "referencingDashboard" */ './pages/Requests/dashboard')
);
const InboundDashboard = lazyWithRetry(() =>
    import(/* webpackChunkName: "inbound" */ './pages/Inbound/Dashboard')
);
const FeaturePreview = lazyWithRetry(() =>
    import(/* webpackChunkName: "featurePreview" */ './pages/FeaturePreview')
);
//Email Forms
const EmailFormContainer = lazyWithRetry(() =>
    import(/* webpackChunkName: "emailFormContainer" */ './containers/email-form-container')
);
const AddRefereeForm = lazyWithRetry(() =>
    import(/* webpackChunkName: "addRefereeForm" */ './components/EmailForms/add-referee-form')
);
const AddReferenceForm = lazyWithRetry(() =>
    import(/* webpackChunkName: "addReferenceForm" */ './components/EmailForms/add-reference-form')
);
const RequestInviteForm = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "requestInviteForm" */ './components/EmailForms/request-invite-form'
    )
);
const ForgotPasswordForm = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "forgotPasswordForm" */ './components/EmailForms/forgot-password-form'
    )
);
const SetPasswordForm = lazyWithRetry(() =>
    import(/* webpackChunkName: "setPasswordForm" */ './components/EmailForms/set-password-form')
);

const lang = window.localStorage.getItem('locale');

axios.defaults.headers.common['Accept-Language'] = lang || 'en-gb';

const { store } = getStore();

const ProtectedRoutes = ({ isAdmin, isSuperAdmin, navigateTo = RouteLinks.unauthorised }) => {
    const { user } = store.getState();
    const adminRoute = isAdmin && user.isAdmin;
    const superAdminRoute = isSuperAdmin && user.isSuperAdmin;
    const authenticated = adminRoute || superAdminRoute;

    return authenticated ? <Outlet /> : <Navigate to={navigateTo} />;
};

const NoMatch = () => (
    <Page title="RefNow | 404">
        <Errorpage />
    </Page>
);

const getEmployerRoute = () => {
    const searchParams = new URLSearchParams(window.location.search);

    return `${RouteLinks.employer}?${searchParams.toString()}`;
};

const SuspenseLoader = ({ children }) => (
    <React.Suspense fallback={<SpinnerWithWrapper size="MD" />}>{children}</React.Suspense>
);

const mixPanelDevToken = 'ac7899d07962dd369ed564ed79487a1a';
const mixPanelProdToken = '563e4d96eb2e7d8119221ed141ed951a';
const hostname = window.location.hostname.toLowerCase();
const pathname = window.location.pathname;

if (hostname.includes('localhost') || hostname.includes('cryptic-silkworm.refnow.co')) {
    mixpanel.init(mixPanelDevToken, { debug: true });
} else {
    mixpanel.init(mixPanelProdToken);

    // if (pathname.includes(RouteLinks.addReferees) || pathname.includes(RouteLinks.addReference)) {
    // }
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<NoMatch />}>
            <Route
                path="/"
                element={
                    <SuspenseLoader>
                        <LoginRegisterContainer />
                    </SuspenseLoader>
                }
            >
                <Route path="/" element={<Navigate to={getEmployerRoute()} />} />
                <Route
                    path={RouteLinks.login}
                    element={
                        <Page title="RefNow | Login">
                            <Login />
                        </Page>
                    }
                />
                <Route
                    path={RouteLinks.register}
                    element={
                        <Page title="RefNow | Register">
                            <Registration />
                        </Page>
                    }
                />
            </Route>
            <Route
                path="/"
                element={
                    <SuspenseLoader>
                        <EmailFormContainer />
                    </SuspenseLoader>
                }
            >
                <Route path={RouteLinks.addReferees} element={<AddRefereeForm />} />
                <Route path={RouteLinks.addReference} element={<AddReferenceForm />} />
                <Route path={RouteLinks.forgotPassword} element={<ForgotPasswordForm />} />
                <Route path={RouteLinks.setPassword} element={<SetPasswordForm />} />
                <Route path={RouteLinks.requestInvite} element={<RequestInviteForm />} />
            </Route>
            <Route path="/" element={<Authorisation />}>
                <Route path={RouteLinks.employer} element={<Layout />}>
                    <Route
                        index
                        element={
                            <SuspenseLoader>
                                <Page title="RefNow | Requests">
                                    <Requests />
                                </Page>
                            </SuspenseLoader>
                        }
                    />
                    <Route
                        exact
                        path={RouteLinks.employerDashboard}
                        element={
                            <SuspenseLoader>
                                <Page title="RefNow | Dashboard">
                                    <ReferencingDashboard />
                                </Page>
                            </SuspenseLoader>
                        }
                    />
                    <Route
                        exact
                        path={RouteLinks.preview}
                        element={
                            <SuspenseLoader>
                                <Page title="RefNow | Feature Preview">
                                    <FeaturePreview />
                                </Page>
                            </SuspenseLoader>
                        }
                    />
                    <Route
                        element={
                            <ProtectedRoutes
                                isAdmin={true}
                                isSuperAdmin={true}
                                navigateTo={`${RouteLinks.preview}?product=talentPool`}
                            />
                        }
                    >
                        <Route
                            exact
                            path={RouteLinks.talentPool}
                            element={
                                <SuspenseLoader>
                                    <Page title="RefNow | Talent Pool">
                                        <TalentPool />
                                    </Page>
                                </SuspenseLoader>
                            }
                        />
                    </Route>
                    <Route
                        exact
                        path={RouteLinks.vacancyPool}
                        element={
                            <SuspenseLoader>
                                <Page title="RefNow | Vacancy Pool">
                                    <VacancyPool />
                                </Page>
                            </SuspenseLoader>
                        }
                    />
                    <Route
                        element={
                            <ProtectedRoutes
                                isAdmin={true}
                                isSuperAdmin={true}
                                navigateTo={`${RouteLinks.preview}?product=inbound`}
                            />
                        }
                    >
                        <Route
                            exact
                            path={RouteLinks.inbound}
                            element={
                                <SuspenseLoader>
                                    <Page title="RefNow | Inbound Dashboard">
                                        <InboundDashboard />
                                    </Page>
                                </SuspenseLoader>
                            }
                        />
                    </Route>
                    <Route
                        exact
                        path={RouteLinks.settings.index}
                        element={
                            <SuspenseLoader>
                                <Settings />
                            </SuspenseLoader>
                        }
                    >
                        <Route
                            index
                            element={
                                <SuspenseLoader>
                                    <Page title="RefNow | Settings">
                                        <AccountSettings />
                                    </Page>
                                </SuspenseLoader>
                            }
                        />
                        <Route
                            exact
                            path={RouteLinks.settings.notifications}
                            element={
                                <SuspenseLoader>
                                    <Page title="RefNow | Notification Settings">
                                        <NotificationSettings />
                                    </Page>
                                </SuspenseLoader>
                            }
                        />
                        <Route
                            exact
                            path={RouteLinks.settings.tours}
                            element={
                                <SuspenseLoader>
                                    <Page title="RefNow | Tours">
                                        <Tours />
                                    </Page>
                                </SuspenseLoader>
                            }
                        />
                        <Route element={<ProtectedRoutes isAdmin={true} isSuperAdmin={true} />}>
                            <Route
                                exact
                                path={RouteLinks.settings.usersAndTeams}
                                element={
                                    <SuspenseLoader>
                                        <Page title="RefNow | Users and Teams">
                                            <UsersAndTeams />
                                        </Page>
                                    </SuspenseLoader>
                                }
                            />
                        </Route>
                        <Route element={<ProtectedRoutes isSuperAdmin={true} />}>
                            <Route
                                exact
                                path={RouteLinks.settings.organisation}
                                element={
                                    <SuspenseLoader>
                                        <Page title="RefNow | Org Settings">
                                            <OrganisationSettings />
                                        </Page>
                                    </SuspenseLoader>
                                }
                            />
                            <Route
                                exact
                                path={RouteLinks.settings.subscription}
                                element={
                                    <SuspenseLoader>
                                        <Page title="RefNow | Subscription">
                                            <SubscriptionSettings />
                                        </Page>
                                    </SuspenseLoader>
                                }
                            />
                            <Route
                                exact
                                path={RouteLinks.settings.buyCredits}
                                element={
                                    <SuspenseLoader>
                                        <Page title="RefNow | Buy Credits">
                                            <BuyCredits />
                                        </Page>
                                    </SuspenseLoader>
                                }
                            />
                        </Route>
                    </Route>
                    <Route
                        element={
                            <SuspenseLoader>
                                <AdminContainer />
                            </SuspenseLoader>
                        }
                    >
                        <Route
                            exact
                            path={RouteLinks.adminQuestionProfiles}
                            element={
                                <Page title="RefNow | Question Profiles">
                                    <QuestionProfileHomepage />
                                </Page>
                            }
                        />
                    </Route>
                </Route>
            </Route>
            <Route
                path={RouteLinks.unauthorised}
                element={
                    <Page title="RefNow | Unauthorised">
                        <Unauthorised />
                    </Page>
                }
            />
        </Route>
    )
);

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
    <PostHogProvider>
        <Provider store={store}>
            <MUIThemeProvider theme={customMuiTheme}>
                <IntlWrapper>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <GlobalStyles />
                        <RouterProvider router={router} />
                    </LocalizationProvider>
                </IntlWrapper>
            </MUIThemeProvider>
        </Provider>
    </PostHogProvider>
);

window.onload = (event) => {
    const { innerWidth, innerHeight } = event.currentTarget;

    store.dispatch(onWindowResize(innerWidth, innerHeight));
    store.dispatch(setDeviceType());
    store.dispatch(setOnlineStatus(window.navigator.onLine));
};
