import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useIntl } from 'react-intl';
import Navigation from '../Navigation';
import routes from '../../constants/routes';
import ReferenceRequest from '../../pages/Requests/request-form';
import { newReferenceRequest, getProfileList } from '../../actions/referenceRequestActions.js';
import { track } from '../../utils/analytics';
import {
    Alert,
    Box,
    Dialog,
    DialogContent,
    Link,
    Slide,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import styled from 'styled-components';

const StyledBillingAlert = styled.div`
    background-color: #c53929;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #fff;
    font-size: 0.875rem;
    padding: 10px 10px 10px 0;
    text-align: center;
`;

const SkipToMainContentLink = styled(Link)(({ theme }) => ({
    position: 'absolute',
    top: '-40px',
    left: 0,
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: '8px',
    zIndex: 100,
    transition: 'top 0.3s',
    textDecoration: 'none',

    ':focus': {
        top: 0,
        color: theme.palette.primary[300],
    },
}));

function isUsingInternetExplorer() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    // const version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
        alert(
            'We no longer support your current browser (Internet Explorer) and you may experience a loss of functionality when navigating our site. We advise that you switch to a modern browser (chrome, firefox, edge) and try again. Thank you!'
        );
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const LoggedInLayout = ({ getProfileList, isSuperAdmin, overdueInvoiceDays }) => {
    const [open, setOpen] = useState(false);
    const { formatMessage } = useIntl();
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // const showDashboardAlert =
    //     location.pathname === routes.employer && !localStorage.getItem('hideDashboardAlert');

    const handleClose = () => {
        if (open) {
            setOpen(false);
            track('Closed request form');
        }
    };

    const handleOpen = () => {
        setOpen(true);
        track('Opened request form', { isMobile: fullScreen });
    };

    useEffect(() => {
        isUsingInternetExplorer();
    }, []);

    useEffect(() => {
        open && getProfileList();
    }, [open]);

    useEffect(() => {
        open && handleClose();
    }, [location.pathname]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const newRequest = searchParams.get('newRequest');
        const tour = searchParams.get('tourId');

        if (newRequest == 1) {
            track('Opened via Ref.New');
            handleOpen();
        }

        if (tour && tour === 'new-request') {
            navigate(routes.settings.tours);
        }
    }, []);

    // const hideDashboardAlert = () => localStorage.setItem('hideDashboardAlert', true);

    return (
        <Box id="logged_in_layout" sx={{ overflow: { xs: 'auto', lg: 'hidden' } }}>
            <SkipToMainContentLink href="#main-content">Skip to Main Content</SkipToMainContentLink>
            {overdueInvoiceDays > 45 && (
                <StyledBillingAlert>
                    {formatMessage({ id: 'Settings.billing.overdueInvoicesMsg' })}
                </StyledBillingAlert>
            )}
            <Navigation newRefRequestBtnClick={handleOpen} closeRefRequestPanel={handleClose} />
            {/* {showDashboardAlert && (
                <Alert
                    severity="info"
                    variant="filled"
                    onClose={hideDashboardAlert}
                    sx={{ borderRadius: '0 !important', bgcolor: 'secondary.100' }}
                >
                    {formatMessage(
                        { id: 'Dashboards.labels.newUpdateMessage' },
                        {
                            a: (msg) => (
                                <Link href={routes.employerDashboard} onClick={hideDashboardAlert}>
                                    {msg}
                                </Link>
                            ),
                        }
                    )}
                </Alert>
            )} */}
            <Outlet />
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                TransitionComponent={Transition}
                disableEscapeKeyDown
                keepMounted
                onClose={handleClose}
                aria-modal="true"
                aria-labelledby="new-request-form"
                aria-describedby="create-new-reference-request"
            >
                <DialogContent sx={{ padding: 0 }}>
                    <ReferenceRequest
                        isMobile={fullScreen}
                        open={open}
                        onClose={handleClose}
                        isSuperAdmin={isSuperAdmin}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = ({ user, locales }) => ({
    creditBal: user.creditBal,
    isSuperAdmin: user.isSuperAdmin,
    locale: locales.locale,
    overdueInvoiceDays: user.most_overdue_invoice_days,
});

const mapDispatchToProps = {
    getProfileList,
    newReferenceRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInLayout);
