const settingsIndex = '/employer/settings';

export default {
    login: '/login',
    register: '/register',
    employer: '/employer',
    employerDashboard: '/employer/dashboard',
    addReferees: '/addReferees',
    addReference: '/addReference',
    forgotPassword: '/forgotPassword',
    setPassword: '/setPassword',
    requestInvite: '/requestInvite',
    settings: {
        index: settingsIndex,
        notifications: `${settingsIndex}/notifications`,
        developer: `${settingsIndex}/developer`,
        organisation: `${settingsIndex}/organisation`,
        subscription: `${settingsIndex}/subscription`,
        billing: `${settingsIndex}/billing`,
        buyCredits: `${settingsIndex}/buyCredits`,
        usersAndTeams: `${settingsIndex}/usersAndTeams`,
        tours: `${settingsIndex}/tours`,
    },
    admin: '/admin',
    talentPool: '/employer/talentPool',
    vacancyPool: '/employer/vacancyPool',
    inbound: '/employer/inbound',
    adminQuestionProfiles: '/employer/admin/questionProfiles',
    unauthorised: '/unauthorised',
    preview: '/employer/preview',
};
