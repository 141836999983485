import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
    Avatar,
    Box,
    Divider,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import {
    HelpOutlineOutlined,
    AddCircleOutlineOutlined,
    SettingsOutlined,
    LayersOutlined,
    LogoutOutlined,
    ChatOutlined,
    TourOutlined,
} from '@mui/icons-material';
import { stringAvatar } from '../../utils/formatter';
import Routes from '../../constants/routes.js';
import { track } from '../../utils/analytics';

function SettingsMenu({ anchorEl, handleClose, user, liveHidden, postLogout }) {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const { organisation, creditBal, paidRTWCreditBal, fullname, isSuperAdmin } = user;

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disablePortal
            keepMounted
            sx={{
                li: {
                    padding: '0.7em 1.5em',
                },
            }}
            slotProps={{
                paper: {
                    id: 'account-menu',
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(-6px 0 10px #312c5d24)',
                        borderRadius: '10px',
                        boxShadow: 'unset',
                        mt: '15px',
                        minWidth: '300px',
                        '& .MuiAvatar-root': {
                            width: 45,
                            height: 45,
                            ml: -0.5,
                            mr: 2,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    handleClose();
                    navigate(Routes.settings.index);
                }}
            >
                <ListItemIcon>
                    <Avatar {...stringAvatar(fullname)} />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="subtitle1" noWrap>
                            {organisation}
                        </Typography>
                    }
                    secondary={
                        <React.Fragment>
                            <Box>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {formatMessage(
                                        { id: 'Header.account.creditsRemaining' },
                                        {
                                            b: (txt) => <b>{txt}</b>,
                                            creditsBalance: creditBal,
                                        }
                                    )}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {formatMessage(
                                        {
                                            id: 'Header.account.digitalIdCreditsRemaining',
                                        },
                                        {
                                            b: (txt) => <b>{txt}</b>,
                                            creditsBalance: paidRTWCreditBal,
                                        }
                                    )}
                                </Typography>
                            </Box>
                        </React.Fragment>
                    }
                />
            </MenuItem>
            <Divider />
            <MenuItem
                id="navigation-interactive-tours"
                onClick={() => {
                    track('Clicked Interactive Tours');
                    handleClose();
                    navigate(Routes.settings.tours);
                }}
            >
                <ListItemIcon>
                    <TourOutlined fontSize="small" />
                </ListItemIcon>
                {formatMessage({ id: 'Header.interactiveTours' })}
            </MenuItem>
            {isSuperAdmin && (
                <MenuItem
                    id="navigation-buyCredits"
                    key="navigation-buyCredits"
                    onClick={() => {
                        handleClose();
                        track('Navigate to Buy Credits page [Account Menu]');
                        navigate(Routes.settings.buyCredits);
                    }}
                >
                    <ListItemIcon>
                        <AddCircleOutlineOutlined fontSize="small" />
                    </ListItemIcon>
                    {formatMessage({ id: 'Header.admin.buyCredits' })}
                </MenuItem>
            )}

            {isSuperAdmin && !liveHidden && (
                <MenuItem
                    id="navigation-subscription"
                    key="navigation-subscription"
                    onClick={() => {
                        handleClose();
                        track('Navigate to Subscription page [Account Menu]');
                        navigate(Routes.settings.subscription);
                    }}
                >
                    <ListItemIcon>
                        <LayersOutlined fontSize="small" />
                    </ListItemIcon>
                    {formatMessage({ id: 'Header.admin.subscription' })}
                </MenuItem>
            )}
            <Divider />
            <MenuItem
                id="navigation-help"
                onClick={() => {
                    track('Navigate to Help page [Account Menu]');
                    return window.open('https://help.refnow.co', '_blank', '');
                }}
                title={formatMessage({ id: 'Header.helpdesk' })}
            >
                <ListItemIcon>
                    <HelpOutlineOutlined fontSize="small" />
                </ListItemIcon>
                {formatMessage({ id: 'Header.help' })}
            </MenuItem>
            <MenuItem
                id="navigation-feedback"
                aria-label="Feedback"
                onClick={() => {
                    track('Clicked Feedback');
                    handleClose();
                    window.postMessage({
                        target: 'FeaturebaseWidget',
                        data: {
                            action: 'openFeedbackWidget',
                            //   setBoard: 'yourboardname', // optional - preselect a board
                        },
                    });
                }}
            >
                <ListItemIcon>
                    <ChatOutlined fontSize="small" />
                </ListItemIcon>
                {formatMessage({ id: 'Header.feedback' })}
            </MenuItem>
            <MenuItem
                id="navigation-settings"
                onClick={() => {
                    track('Navigate to Settings [Account Menu]');
                    handleClose();
                    navigate(Routes.settings.index);
                }}
            >
                <ListItemIcon>
                    <SettingsOutlined fontSize="small" />
                </ListItemIcon>
                {formatMessage({ id: 'Header.account.settings' })}
            </MenuItem>
            <Divider />
            <MenuItem
                id="navigation-logout"
                onClick={() => {
                    handleClose();
                    postLogout();
                }}
            >
                <ListItemIcon>
                    <LogoutOutlined fontSize="small" />
                </ListItemIcon>
                {formatMessage({ id: 'Header.account.logout' })}
            </MenuItem>
        </Menu>
    );
}

export default SettingsMenu;
